.unread-msg-user .badge {
  background-color: #6E00C4; /* verde similar ao do WhatsApp */
  color:#C6F602;
}

.text-end {
  display: flex;
  align-items: center;
}

.text-end .btn {
  margin-left: 4px;
}
.user-name{
  font-size: 16px;
    max-width: 100px;
    font-weight: bold;

}
.message-preview {
  font-size: 12px;
}