// 
// _modal.scss
// 

.modal-header-colored {
    .modal-header {
        background-color:  rgba(var(--bs-primary-rgb), 1);
        margin: 0 -1px -1px -1px;
    }
}

