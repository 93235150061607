/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}



a {
  text-decoration: none !important;
}

label {
  font-weight: 500;
}

@media (min-width:1400px) {
  .container {
    max-width: 1140px;
  }
}

.mt-n4 {
    margin-top: -1.5rem!important;
}

.mt-n5 {
    margin-top: -3rem!important;
}

