//
// Sidemenu
//
@import "../../variables-connect";

.side-menu {
    min-width: 75px;
    max-width: 75px;
    height: 100vh;
    min-height: 570px;
    background-color: $bg-dark;
    display: flex;
    z-index: 9;
    border-right: 1px solid $bg-dark;
    padding: 0 8px;

    //@media (max-width: 991.98px) {
    //    position: fixed;
    //    bottom: 0;
    //    min-width: 100%;
    //    min-height: auto;
    //    border-top: 1px solid $sidebar-bg;
    //}

    .navbar-brand-box {
        text-align: center;
        margin-top: 10px;


        @media (max-width: 991.98px) {
            display: none;
        }


        .logo-light {
            display: $display-none;
        }
    }
}

.sidemenu-navigation {
    height: 100%;
    @media (max-width: 991.98px) {
        width: 100%;

        .tooltip {
            display: none;
        }
    }
}

.side-menu-nav {
    height: 100%;
    flex-direction: column;
    @media (max-width: 991.98px) {
        justify-content: space-between !important;
        flex-direction: row;
    }
    .nav-item {
        margin: 7px 0;
        display: block;
        width: 100%;

        @media (max-width: 991.98px) {
            flex-basis: 0;
            flex-grow: 1;
            margin: 5px 0;
        }
        .nav-link {
            text-align: center;
            font-size: 28px;
            color: $sidebar-menu-item-color;
            width: 100%;
            height: 56px;
            line-height: 56px;
            border-radius: 0;
            padding: 0;
            position: relative;

            i {
                vertical-align: middle;
            }

            &::before {
                position: absolute;
                content: "";
                height: 32px;
                width: 4px;
                border-radius: 24px;
                right: -8px;
                top: 18px;
                @media (max-width: 991.98px) {
                    width: 34px;
                    height: 4px;
                    right: auto;
                    top: -5px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            @media (max-width: 991.98px) {
                font-size: 20px;
                width: 48px;
                height: 48px;
                line-height: 48px;
                margin: 0px auto;
            }
            // altera as cores dos icones ativos
            &.active {
                background-color: transparent;
                color: white;

                &::before {
                    background-color: #6E00C4;
                }
            }
        }

        &.show > .nav-link {
            color: rgba(var(--bs-primary-rgb), 1);
        }
    }

    .profile-user {
        height: 36px;
        width: 36px;
        background-color: var(--#{$prefix}border-color);
        padding: 3px;
    }
}

.light-mode {
    display: none;
}

body[data-bs-theme="dark"] {
    .light-mode {
        display: inline-block;
    }

    .dark-mode {
        display: none;
    }
}

body[data-bs-theme="dark"] {
    .side-menu-nav {
        .nav-item {
            .nav-link {
                &.light-dark {
                    .bx-moon {
                        &:before {
                            content: "\eb90";
                        }
                    }
                }
            }
        }
    }

    .light-mode {
        display: inline-block;
    }

    .dark-mode {
        display: none;
    }
}
