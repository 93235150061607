@import "./icons.scss";
@import "./bootstrap.scss";
@import "./app.scss";
@import "./custom.scss";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

html, body {
  font-family: 'Poppins', sans-serif;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

* {
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 56px;
  font-weight: bold;
}

.subtitle {
  font-size: 20px;
  font-weight: 600;
}

.text-large {
  font-size: 18px;
  font-weight: normal;
}

.text-regular {
  font-size: 16px;
  font-weight: normal;
}

aside.EmojiPickerReact.epr-main {
    position: absolute !important;
    bottom: 82px;
    z-index: 2;
    left: 23px;
}

aside.EmojiPickerReact.epr-main {
    background-color: var(--#{$prefix}secondary-bg);
    border-color: var(--#{$prefix}border-color) !important;
}

.EmojiPickerReact .epr-search-container input.epr-search {
    background-color: var(--#{$prefix}tertiary-bg) !important;
    border-color: var(--#{$prefix}border-color) !important;
}

.EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label {
    background-color: var(--#{$prefix}tertiary-bg) !important;
    color: var(--#{$prefix}tertiary-color) !important;
}

.hide-resize-observer {
  display: none !important;
}
