//
// Forms.scss
//


[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
 &::placeholder{
   text-align: left; /*rtl: right*/
 }
}


.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

.form-check-input:checked {
  background-color: rgba(var(--bs-primary-rgb), 1);
  border-color: rgba(var(--bs-primary-rgb), 1);
}

