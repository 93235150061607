// Variáveis
$bg-color: #f4f4f4;
$bg-dark:#333;
$logo-margin-top-lg: 15em;
$logo-margin-top-md: 12em;
$logo-margin-top-sm: 8.5em;
$logo-height-lg: 10em;
$logo-height-md: 8em;
$logo-height-sm: 6em;
$logo-height-xs: 4em;
$logo-height-xxs: 3em;
$welcome-text-color: #C6F602;
$welcome-highlight-color: #93f090;
$footer-link-hover-color: #93f090;
$footer-text-color: white;
$box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
$border-radius: 1em;
$padding-lg: 2.5em;
$padding-md: 1.5em;
$padding-sm: 1em;
$padding-xs: 0.5em;
$text-color: #333;