.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;

  .custom-modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    width: 90%;
    max-width: 450px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    z-index: 1051;
    position: relative;
    transition: all 0.3s ease;
    text-align: center;

    .custom-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -20px -20px 20px -20px;
      background-color: #2E2E2E;
      padding: 10px 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      color: #fff;

      h4 {
        margin: 0;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffffff;
      }

      .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #ffffff;

        &:hover {
          color: #cccccc;
        }
      }
    }

    .custom-modal-body {
      font-size: 1rem;
      color: #333;
      line-height: 1.6;

      img {
        max-width: 50px;
        margin-bottom: 15px;
      }

      input[type="text"] {
        width: 100%;
        padding: 10px 15px;
        font-size: 1rem;
        border: 2px solid #e6e6e6;
        border-radius: 10px;
        margin-bottom: 20px;
        transition: border-color 0.3s;

        &:focus {
          border-color: #4e3699;
          outline: none;
        }
      }

      button {
        padding: 10px 20px;
        font-size: 1rem;
        font-weight: 600;
        color: #ffffff;
        background-color: #4e3699;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #3d2b7a;
        }
      }
    }
  }

  .custom-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }
}


@media (max-width: 768px) {
  .custom-modal-content {
    width: 80%;
    padding: 15px;
  }

  .custom-modal-header {
    flex-direction: column;
    align-items: flex-start;
    h4 {
      font-size: 1.1rem;
    }
    .close-button {
      align-self: flex-end;
    }
  }
}

@media (max-width: 480px) {
  .custom-modal-content {
    width: 90%;
    padding: 10px;
  }

  .custom-modal-header {
    h4 {
      font-size: 1rem;
    }
  }

  .custom-modal-body {
    font-size: 0.9rem;
    img {
      max-width: 40px;
    }
    input[type="text"] {
      font-size: 0.9rem;
      padding: 8px 12px;
    }
    button {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  }
}
