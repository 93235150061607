.fixed-icon {
    position: fixed;
    bottom: 30px;
    right: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;

    &:hover {
        background-color: #f0f0f0;
    }

    @media (max-width: 900px) {
        bottom: 120px;
    }
}

.tooltip-bubble {
    position: fixed;
    bottom: 70px;
    right: 40px;
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    cursor: pointer;
    font-size: 14px;
    max-width: 200px;
    text-align: center;
    display: flex;
    justify-content: space-between;

    .close-tooltip {
        margin-left: 10px;
        cursor: pointer;
    }
}

.status-text {
    position: fixed;
    bottom: 40px;
    right: 60px;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-size: 12px;
    font-weight: bold;

    @media (max-width: 900px) {
        bottom: 90px;
    }
}