@import "./variaveis-faleai";

.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: $white;
  opacity: 0.7;
  z-index: 999;
  .status {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% -50%);
    .sr-only {
      display: none !important;
    }
  }
}

.modal-title-custom {
  .modal-title {
    font-size: 16px !important;
  }
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
  }
}

.alert-dismiss-custom {
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}

.user-chat-topbar .topbar-bookmark .close {
  padding: 12px 20px;
}

.cursor-pointer {
  cursor: pointer;
}

/* formulario foco */
.form-control:focus {
  border-color: #6e00c4;
  box-shadow: 0 0 0 0.25rem rgba(110, 0, 196, 0.2);
}

//Modal Styles
#logo {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  flex-wrap: wrap;

  img {
    max-width: 7em;
    height: auto;
  }

  h1 {
    span {
      color: #b7de1a;
    }
  }
}

// Qr Code  styles
.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 12.5em;
    height: 12.5em;
  }

  .qr-code-tooltip {
    width: 12.5em;
    height: auto;
  }

  button {
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  #pills-new-account-tab {
    display: none;
  }
}

//  alinhamento titulo
.title-form {
  text-wrap: nowrap;
  padding: 0.25em 0;
}

// btn styles

.btn-logout {
  color: #b7de1a !important;
  font-weight: bold !important;
}

.bg-primary {
  background-color: $background-color-primary !important;
}

.avatar-title {
  background-color: $background-color-primary !important;
}

.icon-second {
  color: white;
}

.language-selector-container {
  position: absolute;
  right: 10px;
  z-index: 1000;
  .language-dropdown-toggle {
    background: none;
    border: none;
    padding: 0;
    &:hover,
    &:focus {
      background: none;
      border: none;
      box-shadow: none;
    }
    .language-icon {
      width: 24px;
      height: 24px;
    }
  }
}

.logout-connectai {
  font-weight: bold;
  font-size: 15px;
  font-weight: bold;
  background: linear-gradient(45deg, #4e3699, #60bfb7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.account-header {
  background-color: #2e2e2e !important;
}

.custom-scrollbar {
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4e3699;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #3b2a73;
  }
}

//* Scroll bar chat*/
#chat-conversation-list {
  scrollbar-width: thin;
  scrollbar-color: rgb(238, 238, 238) transparent;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0b0b0;
  }
}