// Cores
$primary-color:#4E3699;
$hover-color: #8200C9;
$gradient-start: #8200C9;
$gradient-end: #6E00C4;
$hover-gradient-start: #C6F602;
$hover-gradient-end: #B7DE1A;
$text-color: #FFFFFF;
$link-color: #1e1e1e;
$link-hover-color: #6E00C4;
$primary-purple: #8200C9;
$highlight-yellow: #C6F602;
$pale-green: #E4FBA9;
$dark-gray: #3B3B3B;
$background-color-primary: rgba(78, 54, 160);
$background-color-second: rgba(147,240,144);

// Classes reutilizáveis
.primary-background {
  background-color: $primary-color;
}

.primary-text {
  color: $primary-color;
}

.hover-color {
  &:hover {
    color: $hover-color;
  }
}

.gradient {
  background-image: linear-gradient($gradient-start, $gradient-end);
}

.hover-gradient {
  &:hover {
    background-image: linear-gradient($hover-gradient-start, $hover-gradient-end);
  }
}

.text-color {
  color: $text-color;
}

.link-color {
  color: $link-color;
}

.link-hover-color {
  &:hover {
    color: $link-hover-color;
  }
}

.primary-purple {
  color: $primary-purple;
}

.highlight-yellow {
  color: $highlight-yellow;
}

.pale-green {
  color: $pale-green;
}

.dark-gray {
  color: $dark-gray;
}
